import React from 'react';
import { HStack, VStack, Flex, Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import DefaultContainer from '../../layouts/DefaultContainer';
import { grayGradientToBottom, grayGradientToTop } from '../../theme/constants';
import ProfileCard from './ProfileCard.jsx';

const RecentRegistrationsDesktop = ({ profilesList, as }) => {
  const { t } = useTranslation('main');
  return (
    <DefaultContainer
      pb={0}
      pt={0}
      pos='relative'
      container={{
        as: 'section',
        bgColor: 'brand.secondaryBackground',
        overflow: 'hidden',
      }}
    >
      <Box
        pos='absolute'
        top='0'
        h={15}
        w='100%'
        zIndex='1'
        bg={grayGradientToTop}
      />
      <Box
        pos='absolute'
        bottom='0'
        h={15}
        w='100%'
        zIndex='1'
        bg={grayGradientToBottom}
      />
      <Flex h={120} py={0} alignItems='center'>
        <Heading as={as || 'h4'} size='2-4xl' mr={20}>
          {t('new-users')}
        </Heading>
        <HStack spacing={5}>
          <VStack spacing={5} animation='scroll 60s linear infinite'>
            {profilesList?.slice(0, 9)?.map(el => (
              <ProfileCard profile={el} key={el?.id} />
            ))}
          </VStack>
          <VStack mb={75} spacing={5} animation='scroll 60s linear infinite'>
            {profilesList?.slice(9, 18)?.map(el => (
              <ProfileCard profile={el} key={el?.id} />
            ))}
          </VStack>
          <VStack spacing={5} animation='scroll 60s linear infinite'>
            {profilesList?.slice(18, 27)?.map(el => (
              <ProfileCard profile={el} key={el?.id} />
            ))}
          </VStack>
          <VStack mb={75} spacing={5} animation='scroll 60s linear infinite'>
            {profilesList?.slice(27, 36)?.map(el => (
              <ProfileCard profile={el} key={el?.id} />
            ))}
          </VStack>
        </HStack>
      </Flex>
    </DefaultContainer>
  );
};

export default RecentRegistrationsDesktop;
