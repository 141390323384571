import React from 'react';
import { Text, Flex, Avatar } from '@chakra-ui/react';
import NextLink from '../../components/NextLink';
import { extractImage } from '../../utils/extractImage';
import { imageSizes } from '../../common/constants';

const ProfileCard = ({ profile }) => (
  <Flex
    flexDir='column'
    borderRadius='xl'
    backgroundColor='white'
    p={4}
    fontSize='xs'
    maxW={48}
  >
    <NextLink href={`/executors/${profile.id}`}>
      <Flex w={38} h='100%'>
        <Avatar
          size='full'
          bgColor='gray.200'
          alt={profile?.name}
          boxSize={38}
          src={extractImage(profile?.image, imageSizes.S)}
          borderRadius='lg'
        />
      </Flex>
      <Text as='div' fontWeight='bold' mt={2}>
        {profile?.name}
      </Text>
    </NextLink>
    <Text as='div'>{profile?.jobTypes?.[0]?.name}</Text>
    <Text as='div' mt={2} color='typography.secondary'>
      {profile?.createdAt}
    </Text>
  </Flex>
);

export default ProfileCard;
